import React from 'react'
import './blue-space.jpg'
import './ash-connell.jpg'
import { ReactComponent as TwitterIcon } from './social-twitter.svg'
import { ReactComponent as MessengerIcon } from './social-messenger.svg'
import { ReactComponent as LinkedInIcon } from './social-linkedin.svg'
import './App.css'

function App() {
  return (
    <div className="app">
      <div className="bg">
        <div className="photo" />
      </div>
      <main className="content">
        <h1>Ash Connell</h1>
        <p className="bio">
          I'm a Software Engineer and a Digital Jedi. I primarily build things
          for the web.
        </p>
        <a className="btn" href="mailto:contact@ashconnell.com">
          Get in touch
        </a>
        <ul>
          <li className="social">
            <a
              href="https://twitter.com/ashconnell"
              aria-label="View my Twitter profile"
            >
              <TwitterIcon className="icon" />
            </a>
          </li>
          <li className="social">
            <a
              href="https://m.me/ashconnell"
              aria-label="Chat with me on Facebook Messenger"
            >
              <MessengerIcon className="icon" />
            </a>
          </li>
        </ul>
      </main>
    </div>
  )
}

export default App
